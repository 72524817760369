{
  "account": {
    "emailConfirmation": {
      "failed": {
        "content": "Bei der Bestätigung ist ein Fehler aufgetreten.\nMöglicherweise wurde dein Konto bereits aktiviert?",
        "headline": "Fehler bei der Bestätigung"
      },
      "loading": {
        "content": "wird bearbeitet"
      },
      "succeeded": {
        "content": {
          "enduser": "Dein Konto wurde erfolgreich bestätigt.",
          "user": "Dein Konto wurde erfolgreich bestätigt.\nDu kannst dieses Fenster jetzt schließen."
        },
        "cta": "Jetzt anmelden",
        "headline": "Herzlich willkommen"
      }
    },
    "passwordReset": {
      "headline": "Passwort ändern",
      "succeeded": {
        "cta": "Jetzt anmelden",
        "message": "Dein Passwort wurde erfolgreich geändert."
      }
    }
  },
  "bankManagement": {
    "basicDataTab": "Allgemeines",
    "editHeadline": "Bank bearbeiten",
    "fields": {
      "active": {
        "description": "Legt fest, ob die Bank für Endnutzer (in der Mobile App) verfügbar ist.",
        "label": "Bank aktiv?"
      },
      "bic": "BIC",
      "disabledCategories": {
        "description": "Diese Module werden den Nutzern in der App nicht angezeigt",
        "label": "Inaktive Module",
        "maxLengthErrorMessage": "Mindestens drei Module müssen aktiviert sein",
        "placeholder": "Module auswählen"
      },
      "email": "E-Mail",
      "estateUser": {
        "description": "Hinterlege hier den Benutzernamen der zur Übertragung der Immobilien von OnOffice verwendet wird (z.B. vr-immo-xyz) ",
        "label": "Benutzername für Immobilien"
      },
      "meinPlus": {
        "apiKey": {
          "label": "API Key"
        },
        "clientId": {
          "label": "Client ID"
        },
        "clientSecret": {
          "label": "Client Secret"
        },
        "description": "Mit der Eingabe gültiger Zugangsdaten, verbindest du die Bank mit der MeinPlus Schnittstelle."
      },
      "phone": "Telefon",
      "title": "Name der Bank"
    },
    "links": {
      "addedValueBankLinks": {
        "addLink": "Link hinzufügen",
        "descriptionMaxLengthHint": "max. {length} Zeichen",
        "disclaimerPlaceholder": "Hinweis für Nutzer in der App bevor der Link geöffnet wird",
        "fields": {
          "description": "Kurzbeschreibung",
          "disclaimer": "Hinweis",
          "title": "Titel",
          "url": "Weblink"
        },
        "headline": "Marketing Links",
        "headlineHint": "Es können bis zu vier Marketing Links verwaltet werden",
        "iconTitle": "Icon",
        "optional": "Optional",
        "remove": "Löschen",
        "selectIcon": "Icon auswählen"
      },
      "appointmentBooking": "Terminvereinbarung",
      "gtc": "AGB",
      "headline": "Links",
      "privacyPolicy": "Datenschutzbestimmungen",
      "terms": "Impressum",
      "termsOfUse": "Nutzungsbedingungen"
    },
    "logo_upload": "Banklogo",
    "meinPlus": {
      "headline": "MeinPlus",
      "invalidCredentials": {
        "fieldMessage": "Ungültige MeinPlus Zugangsdaten. Bitte überprüfe die Werte und speichere erneut.",
        "toastMessage": "Ungültige MeinPlus Zugangsdaten"
      },
      "title": "Zugangsdaten"
    },
    "menu": {
      "banks": "Banken"
    },
    "profileHeadline": "Bank Profil",
    "videoCalls": {
      "afterHoursPhone": "Telefonnr. Service Center (außerhalb Servicezeiten)",
      "businessHours": {
        "break": "Pause",
        "headline": "Servicezeiten",
        "hours": "von/bis"
      },
      "headline": "Servicezeiten",
      "videocallsAvailable": "Videotelefonie verfügbar?"
    }
  },
  "bankSelect": {
    "control": {
      "groups": {
        "banks": "Banken"
      },
      "label": "Modus",
      "placeholder": "Bank auswählen",
      "vrstarOptionTitle": "VR Star"
    },
    "page": {
      "description": "Bitte wähle zuerst eine Bank aus, um ihn diesem Bereich arbeiten zu können.",
      "headline": "Bank wählen"
    }
  },
  "contentFeed": {
    "attachments": {
      "description": "Füge bis zu {maxFiles} zusätzliche Dateien hinzu (Bilder oder PDFs). Maximale Dateigröße von {fileSize} MB",
      "headline": "Anhänge"
    },
    "contentType": {
      "article": "Artikel",
      "file": "Datei (z.B. Magazin)",
      "link": "Externen Beitrag (Link)",
      "short": {
        "article": "Artikel",
        "file": "Datei",
        "link": "Link"
      }
    },
    "create_title": "Neuen Beitrag anlegen",
    "edit_title": "Beitrag bearbeiten",
    "errors": {
      "requiredContent": "Dieses Feld ist erforderlich für den Inhaltstyp \"{contentType}\""
    },
    "fields": {
      "backLink": "Link",
      "contentType": "Art des Inhalts",
      "type": "Typ"
    },
    "file": {
      "attachments": {
        "description": "Füge die Datei im PDF Format hinzu. Maximale Dateigröße von {fileSize} MB",
        "headline": "Datei"
      },
      "image": {
        "description": "Maximale Dateigröße von {fileSize} MB. Optional, wenn nicht hinterlegt wird versucht automatisch ein Bild aus der PDF Datei zu erstellen."
      }
    },
    "header_title": "News",
    "image": {
      "description": "Maximale Dateigröße von {fileSize} MB",
      "headline": "Vorschaubild"
    },
    "photoCopyright": "Bildrechte",
    "tags": {
      "culture": "Kultur",
      "estate": "Immobilien",
      "event": "Veranstaltungen",
      "music": "Musik",
      "news": "News",
      "politics": "Politik",
      "sport": "Sport"
    },
    "type": {
      "bank": "Bank",
      "rss": "RSS",
      "vrstar": "VRStar"
    }
  },
  "copyrights": {
    "headers": {
      "attachment_copyrights": "Bildrechte aktualisieren",
      "image_copyrights": "Bildrechte aktualisieren"
    },
    "labels": {
      "image": "Bildrechte"
    },
    "no_images": "Es wurden keine Bilder gefunden"
  },
  "entity_history": {
    "history": "Änderungsverlauf",
    "record_history": "Änderung protokollieren",
    "terms": {
      "changes": "Änderungen",
      "record_created": "Datensatz erstellt",
      "record_deleted": "Datensatz gelöscht"
    }
  },
  "event": {
    "additionalLinks": "Links",
    "additionalLinks_title": "Titel",
    "additionalLinks_url": "Weblink",
    "attachments": "Anhänge",
    "audience": "Zielgruppe",
    "cancelled": {
      "button": "Als abgesagt markieren",
      "label": "Abgesagt",
      "revertButton": "Status zurücksetzen",
      "success": "Der \"abgesagt\" Status wurde aktualisiert"
    },
    "categories": "Kategorie",
    "checkoutURL": "Checkout URL",
    "dates": {
      "headline": "Zeitraum",
      "modalHeadline": "Wiederkehrende Veranstaltung",
      "recurrence": {
        "label": "Wiederholung"
      }
    },
    "declineMessage": "Nachricht",
    "description": "Beschreibung",
    "disabledAccessible": "Barrierefrei zugänglich",
    "dresscode": "Kleiderordnung",
    "dresscodeDescription": "Beschreibung der Kleiderordnung",
    "emergencyContact": "Notfallkontakt",
    "endDateTime": "Enddatum",
    "maxPrice": "Preis max.",
    "minPrice": "Preis min.",
    "openingDateTime": "Einlass",
    "organizer": "Veranstalter",
    "photoCopyright": "Bildrechte",
    "photoURL": "Foto",
    "placeholder": {
      "audience": "Zielgruppe auswählen",
      "dresscode": "Kleiderordnung auswählen",
      "organizer": "Veranstalter auswählen",
      "venue": "Veranstaltungsort auswählen"
    },
    "price": "Preis",
    "priceType": {
      "freeOfCharge": "kostenlos",
      "unknown": "keine Preisangabe",
      "withCosts": "kostenpflichtig"
    },
    "soldOut": {
      "button": "Als ausverkauft markieren",
      "label": "Ausverkauft",
      "revertButton": "Status zurücksetzen",
      "success": "Der \"ausverkauft\" Status wurde aktualisiert"
    },
    "sponsoredByBank": {
      "label": "Bankgesponsert"
    },
    "startDateTime": "Startdatum der Veranstaltung",
    "state": "Status",
    "statusFlags": "Veranstaltungsstatus",
    "title": "Titel",
    "venue": "Veranstaltungsort",
    "weatherProtection": "Witterungsschutz"
  },
  "event_management_page": {
    "abort_attachment_edit": "Bearbeiten der Anhänge abbrechen",
    "add_attachment": "Anhang hinzufügen",
    "add_attachments": "Anhang hinzufügen",
    "add_organizer": "Veranstalter anlegen",
    "add_venue": "Veranstaltungsort anlegen",
    "additionalLinks": {
      "addLink": "Link hinzufügen",
      "headline": "Links",
      "headlineHint": "Es können bis zu vier Links verwaltet werden"
    },
    "attachment_disclaimer": "Füge bis zu {maxFiles} zusätzliche Dateien zu deiner Veranstaltung hinzu (Bilder oder PDFs). Maximale Dateigröße von {fileSize} MB",
    "description": "Beschreibung",
    "edit_attachments": "Anhänge bearbeiten",
    "event_details": "Veranstaltungsdetails",
    "file_preview": "Anhänge",
    "headlines": {
      "createEvent": "Neue Veranstaltung anlegen",
      "createOrganizer": "Neuen Veranstalter anlegen",
      "createVenue": "Neuen Veranstaltungsort anlegen",
      "editEvent": "Veranstaltung bearbeiten",
      "editOrganizer": "Veranstalter bearbeiten",
      "editVenue": "Veranstaltungsort bearbeiten"
    },
    "image_preview": "Bild",
    "import": {
      "downloadTemplateCta": "Vorlage herunterladen",
      "headline": "Veranstaltungen importieren"
    },
    "list": {
      "address": "Adresse",
      "city": "Stadt",
      "country": "Land",
      "email": "E-Mail",
      "end_time": "Endzeit",
      "image": "Bild",
      "organizer_title": "Veranstalter",
      "price": "Preis",
      "start_time": "Startzeit",
      "title": "Titel",
      "venue_title": "Veranstaltungsort",
      "zip": "PLZ"
    },
    "list_events": "Veranstaltungsliste",
    "list_organizers": "Liste der Veranstalter",
    "menu": {
      "events": "Veranstaltungen",
      "import": "Import",
      "organizers": "Veranstalter",
      "venues": "Veranstaltungsorte"
    },
    "no_events_found": "Keine Veranstaltungen gefunden",
    "prices": {
      "additionalPrices": {
        "addCta": "Preis hinzufügen",
        "description": "Beschreibung",
        "headline": "Zusätzliche Preiskategorien",
        "price": "Preis",
        "title": "Titel"
      },
      "defaultPrice": "Standard Preis",
      "defaultPriceDescription": "Beschreibung für Standard Preis",
      "headline": "Preise"
    },
    "resource_id_missing": "Ressourcen-ID fehlt",
    "restrictions": "Maximale Dateigröße von {size} MB",
    "upload_image": "Bild"
  },
  "file_upload": {
    "error": {
      "max_files": "Du kannst maximal {maxFiles} Dateien hochladen.",
      "requirements": "Die Datei muss vom Typ {filetype} und kleiner als {size} sein.",
      "upload_error": "Fehler beim Hochladen der Datei. Bitte versuche es später noch einmal",
      "wrong_file_type": "Bitte überprüfen die Upload-Anforderungen und versuche es erneut."
    },
    "idle": "Datei hier ablegen oder {'<'}span class='filepond--label-action'{'>'} Durchsuchen {'<'}span{'>'}",
    "max_file_size": "Die maximale Dateigröße beträgt {filesize}",
    "max_file_size_exceeded": "Datei ist zu groß."
  },
  "firebase_error": {
    "auth/invalid-email": "Benutzername oder Passwort sind falsch",
    "auth/invalid-login-credentials": "Der Benutzername oder das Passwort ist falsch",
    "auth/user-disabled": "Der Benutzer, der der angegebenen E-Mail-Adresse entspricht, wurde deaktiviert.",
    "auth/user-not-found": "Kein Benutzer mit der angegebenen E-Mail gefunden.",
    "auth/wrong-password": "Benutzername oder Passwort sind falsch",
    "unknown": "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später noch einmal",
    "wrong_credentials": "Benutzername oder Passwort sind falsch"
  },
  "forms": {
    "confirm_password": "Passwort wiederholen",
    "contact": {
      "description": "Wie können dich Interessenten erreichen? Gib bspw. eine Telefonnummer oder E-Mail Adresse an.",
      "label": "Kontakt"
    },
    "content": "Inhalt",
    "contentType": "Typ",
    "create_new_user": "Neuen Benutzer anlegen",
    "description": "Beschreibung",
    "detach_coordinates": "Koordinaten manuell setzen",
    "discountedDebit": {
      "description": "bei der Zahlung mit der goldenen girocard",
      "label": "Vorteil/Rabatt"
    },
    "edit_user": "Benutzer bearbeiten",
    "email": "E-Mail",
    "firstname": "Vorname",
    "headers": {
      "organizer": {
        "details": "Veranstalter еinzelheiten"
      },
      "venue": {
        "details": "Veranstaltungsort еinzelheiten"
      }
    },
    "new_password": "Neues Kennwort",
    "partner": "MeinPlus Partner",
    "password": "Passwort",
    "placeholder": {
      "email": "E-Mail-Adresse",
      "firstname": "Vorname",
      "password": "Passwort",
      "select_bank": "Bank auswählen",
      "select_partner": "MeinPlus Partner auswählen",
      "select_role": "Rolle auswählen",
      "surname": "Nachname",
      "tags": "Wählen Sie Tags aus",
      "username": "Nutzername",
      "valid_for": "Gültig für"
    },
    "price": "Preis",
    "publish_date": "Datum der Veröffentlichung",
    "reveal_password": "Passwort anzeigen",
    "role": "Rolle",
    "start_date": "Startdatum der Veranstaltung",
    "subtitle": "Untertitel",
    "surname": "Nachname",
    "tags": "Tags",
    "title": "Titel",
    "type": "Typ",
    "username": "Nutzername",
    "valid_for": "Typ",
    "validity": {
      "day": "Tagesangebot",
      "month": "Monatsangebot",
      "week": "Wochenangebot"
    },
    "validityStart": {
      "description": {
        "day": "Das Angebot ist nur an diesem Tag gültig",
        "month": "Das Angebot gilt für einen Monat ab diesem Datum",
        "week": "Das Angebot gilt für eine Woche ab diesem Datum"
      },
      "label": "Gültigkeitsbeginn"
    }
  },
  "generic": {
    "Yes": "Ja",
    "back": "zurück",
    "bank": "Bank",
    "cancel": "Abbrechen",
    "confirm": "Bestätigen",
    "confirmation": "Bestätigung",
    "content_categories": {
      "estate": "Immobilien",
      "events": "Veranstaltungen",
      "feed": "News",
      "partners": "Partner und Angebote",
      "seniors": "Seniorenhilfe",
      "clubs": "Vereine"
    },
    "continue": "Weiter",
    "copyright_saved": "Bildrechte erfolgreich gespeichert",
    "create": "Anlegen",
    "createNew": "Neu",
    "data_table": {
      "confirm": "Bestätigen",
      "contact": "Kontakt",
      "date": "Datum",
      "has_been_accepted": "Akzeptiert?",
      "open_immo_object_id": "Objektnummer",
      "page": "Seite",
      "price": "Preis",
      "result_count": "Ergebnisse {min}-{max} von {total}",
      "tags": "Stichworte",
      "title": "Titel",
      "type": "Typ",
      "validity_start": "Gültigkeitsbeginn"
    },
    "delete": "Löschen",
    "deleteConfirmation": {
      "header": "Löschen bestätigen",
      "message": "Bist du sicher, dass du diesen Eintrag löschen willst?"
    },
    "discard": {
      "message": "Bist du sicher, dass du die Änderungen verwerfen willst?"
    },
    "edit": "Bearbeiten",
    "editor": {
      "bold": "Fett",
      "increaseIndent": "Einzug erhöhen",
      "italic": "Kursiv",
      "link": "Link",
      "orderedList": "Nummerierung",
      "reduceIndent": "Einzug verkleinern",
      "size": {
        "h1": "Überschrift 1",
        "h2": "Überschrift 2",
        "normal": "Normal"
      },
      "underline": "Unterstrichen",
      "unorderedList": "Aufzählung"
    },
    "error": "Fehler",
    "errors": {
      "bicMismatch": "Diese Import Datei wurde für eine andere Bank erstellt.",
      "invalidRequestBody": "Die Anfrage war ungültig. Bitte versuche es später erneut.",
      "unknownError": "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut."
    },
    "filepond": {
      "fileValidateTypeLabelExpectedTypes": "Erwartet {allButLastType} oder {lastType}",
      "imageValidateSizeLabelExpectedMaxResolution": "Maximale Auflösung: {maxResolution}",
      "imageValidateSizeLabelExpectedMaxSize": "Maximale Größe: {maxWidth} × {maxHeight}",
      "imageValidateSizeLabelExpectedMinResolution": "Mindestauflösung: {minResolution}",
      "imageValidateSizeLabelExpectedMinSize": "Mindestgröße: {minWidth} × {minHeight}",
      "imageValidateSizeLabelFormatError": "Bildtyp nicht unterstützt",
      "imageValidateSizeLabelImageResolutionTooHigh": "Auflösung ist zu hoch",
      "imageValidateSizeLabelImageResolutionTooLow": "Auflösung ist zu niedrig",
      "imageValidateSizeLabelImageSizeTooBig": "Bild ist zu groß",
      "imageValidateSizeLabelImageSizeTooSmall": "Bild ist zu klein",
      "labelButtonAbortItemLoad": "Verwerfen",
      "labelButtonAbortItemProcessing": "Abbrechen",
      "labelButtonProcessItem": "Upload",
      "labelButtonRemoveItem": "Entfernen",
      "labelButtonRetryItemLoad": "Erneut versuchen",
      "labelButtonRetryItemProcessing": "Erneut versuchen",
      "labelButtonUndoItemProcessing": "Rückgängig",
      "labelFileLoadError": "Fehler beim Laden",
      "labelFileLoading": "Laden",
      "labelFileProcessing": "Upload läuft",
      "labelFileProcessingAborted": "Upload abgebrochen",
      "labelFileProcessingComplete": "Upload abgeschlossen",
      "labelFileProcessingError": "Fehler beim Upload",
      "labelFileProcessingRevertError": "Fehler beim Wiederherstellen",
      "labelFileRemoveError": "Fehler beim Löschen",
      "labelFileSizeNotAvailable": "Dateigröße nicht verfügbar",
      "labelFileTypeNotAllowed": "Dateityp ungültig",
      "labelFileWaitingForSize": "Dateigröße berechnen",
      "labelInvalidField": "Feld beinhaltet ungültige Dateien",
      "labelMaxFileSize": "Maximale Dateigröße: {filesize}",
      "labelMaxFileSizeExceeded": "Datei ist zu groß",
      "labelMaxTotalFileSize": "Maximale gesamte Dateigröße: {filesize}",
      "labelMaxTotalFileSizeExceeded": "Maximale gesamte Dateigröße überschritten",
      "labelTapToCancel": "abbrechen",
      "labelTapToRetry": "erneut versuchen",
      "labelTapToUndo": "rückgängig",
      "lableIdle": "Dateien ablegen oder {'<'}span class='filepond--label-action'{'>'} auswählen {'<'}span{'>'}"
    },
    "filter": {
      "apply": "Anwenden",
      "buttonLabel": "Filter"
    },
    "formValidationFailed": "Fehler bei der Validierung, bitte überprüfe das Formular",
    "freeOfCharge": "kostenlos",
    "go_back": "Zurück",
    "image": "Bild",
    "imageCopyrightsHint": {
      "buttonLabel": "Bildrechte",
      "tooltipContent": "Klicke auf die Vorschau eines Bildes um Bildrechte zu hinterlegen.{'<'}br{'>'}{'<'}br{'>'}{'<'}strong{'>'}Achtung{'<'}/strong{'>'}{'<'}br{'>'}Bei neu hochgeladene Bildern muss vorher einmal gespeichert werden!"
    },
    "linkedBank": "Dein Konto ist mit der Bank {'<'}strong{'>'}{bank}{'<'}/strong{'>'} verknüpft",
    "listEmpty": "Keine Einträge gefunden",
    "locales": {
      "de": "Deutsch",
      "en": "Englisch"
    },
    "logout": "Abmelden",
    "no": "nein",
    "ok": "Ok",
    "open_page": "Seite öffnen",
    "publicHoliday": "Feiertag",
    "publish": "Veröffentlichen",
    "published": "Eintrag veröffentlicht",
    "recordStateInfo": {
      "ACTIVE": "Aktiv",
      "DRAFT": "Entwurf",
      "REVIEW": "Warten auf Freigabe",
      "descriptions": {
        "ACTIVE": "Der Beitrag wurde bestätigt und wird nun in der App angezeigt.",
        "DRAFT": "Der Beitrag wurde noch nicht eingereicht und kann noch bearbeitet werden.",
        "REVIEW": "Der Beitrag wurde eingereicht und wartet auf Bestätigung der Bank."
      },
      "label": "Status"
    },
    "replace_changes": "Bist du sicher, dass du die aktuellen Änderungen ersetzen möchten?",
    "reset": "Zurücksetzen",
    "save": "Speichern",
    "saved": "Eintrag gespeichert",
    "search": "Suche",
    "searchPlaceholder": "Suche nach",
    "selectPlaceholder": "Bitte auswählen",
    "submitForReview": "Zur Überprüfung einreichen",
    "submittedForReview": "Eintrag zur Überprüfung eingereicht",
    "success": "Erfolg",
    "timepicker": {
      "cancel": "abbrechen",
      "select": "speichern"
    },
    "title": "Titel",
    "unPublish": "Zurück zu Entwurf",
    "unPublished": "Eintrag in den Entwurf-Status zurückgesetzt",
    "unsaved_changes": "Du hast nicht gespeicherte Änderungen. Möchtest du wirklich zurückgehen?",
    "unsaved_changes_header": "Nicht gespeicherte Änderungen",
    "warning": "Warnung",
    "yes": "ja"
  },
  "global_modals": {
    "choose_password": "Wählen ein Passwort, das eine Kombination aus Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen enthält, um die Sicherheit zu gewährleisten.",
    "password_change_required": "Passwortänderung erforderlich",
    "reset_password": "Passwort zurücksetzen",
    "reset_password_email_sent": "An deine E-Mail-Adresse wurde eine E-Mail mit Anweisungen zum Zurücksetzen deines Passworts gesendet.",
    "reset_password_text": "Gib deine E-Mail-Adresse ein und wir senden dir einen Link zum Zurücksetzen deines Passworts."
  },
  "importApp": {
    "assets": {
      "description": "Bitte lade alle Dateien hoch, die für den Import benötigt werden.",
      "headline": "Zusätzliche Dateien hochladen",
      "list": {
        "missingTooltip": "Diese Datei fehlt noch",
        "show": "Dateiliste anzeigen",
        "title": "Liste der benötigten Dateien",
        "uploadedTooltip": "Diese Datei wurde bereits ausgewählt"
      },
      "placeholder": "Datei(en) ablegen oder {'<'}span class=\"filepond--label-action\"{'>'} auswählen {'<'}/span{'>'}"
    },
    "downloadUsedImportFile": "Herunterladen",
    "fields": {
      "createdAt": "Erstellt am",
      "creator": "Erstellt von",
      "excelFile": "Import Datei",
      "recordsCount": "Verknüpfte Datensätze",
      "status": "Status"
    },
    "reset": "Neu starten",
    "resetConfirmation": {
      "header": "Bestätigen",
      "message": "Bist du sicher, du den aktuellen Status zurücksetzen willst?"
    },
    "status": {
      "draft": "Entwurf",
      "failed": "Fehlgeschlagen",
      "running": "In Bearbeitung",
      "succeeded": "Erfolgreich",
      "undone": "Rückgängig gemacht"
    },
    "submit": "Import starten",
    "validationFailed": {
      "errorLine": "Zeile {row}:",
      "headline": "Validierung fehlgeschlagen",
      "message": "Die Excel Datei ist zumindest teilweise fehlerhaft. Folgend findest du eine Liste der aufgetretenen Probleme. Bitte behebe diese in der Excel Datei und starte den Import erneut."
    },
    "validationSuccessMessage": "Die Excel Datei wurde erfolgreich validiert!",
    "validationUploader": {
      "placeholder": "Excel Datei ablegen oder {'<'}span class=\"filepond--label-action\"{'>'} auswählen {'<'}/span{'>'}"
    }
  },
  "login_page": {
    "footer_legal": "Impressum",
    "footer_privacy": "Datenschutzrichtlinie",
    "forgot_password": "Passwort vergessen?",
    "hero_paragraph": "Finde exklusive Dienstleistungen und Vorteile, egal wo du bist!",
    "hero_title": "Deine Region in Reichweite.",
    "login": "Anmelden",
    "password_change_success": "Das Passwort wurde erfolgreich geändert. Bitte melde dich erneut an.",
    "redirect_notice": "Du wirst nach der Anmeldung weitergeleitet.",
    "register": "Du hast noch kein Konto? Jetzt registrieren",
    "title": "Anmelden",
    "unauthorized_logout": "Du wurdest automatisch abgemeldet. Bitte melde dich erneut an."
  },
  "notifications": {
    "categories_fail": "Ereigniskategorien konnten nicht abgerufen werden",
    "clipboard_success": "In die Zwischenablage kopiert",
    "delete_success": "Erfolgreich gelöscht",
    "error": "Fehler",
    "fail": "Fehler",
    "info": "Information",
    "invalid_form": "Ungültiges Formular, bitte überprüfe die Felder",
    "location_updated": "Standort erfolgreich aktualisiert",
    "no_changes": "Keine Änderungen vorgenommen",
    "options_fail": "Fehler beim Abrufen der Veranstaltungsoptionen",
    "reset_email_sent": "E-Mail zum Zurücksetzen des Passworts an {email} gesendet",
    "success": "Erfolg"
  },
  "offer_management": {
    "create_title": "Neues Angebot anlegen",
    "edit_title": "Angebot bearbeiten",
    "menu": {
      "partner_offers": "Partnerangebote"
    },
    "submit_success": "Das Angebot wurde erfolgreich zur Prüfung eingereicht"
  },
  "organizer": {
    "address": "Adresse",
    "city": "Stadt",
    "countryCode": "Land",
    "email": "E-Mail",
    "emergencyTel": "Notfalltelefon",
    "tel": "Telefon",
    "title": "Titel",
    "zip": "PLZ"
  },
  "recurrenceSettings": {
    "end": {
      "dateRequiredMessage": "Bitte wähle ein Datum aus",
      "headline": "Ende",
      "repeatsLabel": "Terminen",
      "typeLabels": {
        "never": "Nie",
        "on_date": "Am",
        "repeats": "Nach"
      }
    },
    "eventOccurrenceTypes": {
      "oneTime": "einmalig",
      "recurring": "wiederkehrend"
    },
    "interval": {
      "every": "Alle",
      "units": {
        "day": "Tag | Tage",
        "month": "Monat | Monate",
        "week": "Woche | Wochen",
        "year": "Jahr | Jahre"
      },
      "weekdaysLabel": "Wiederholen am"
    },
    "submitCta": "Fertig"
  },
  "registration": {
    "are_you": "Bist du",
    "change_image": "Bild ändern",
    "create_organization": "Veranstalter anlegen",
    "data_processing": "Zustimmung zu den {link}",
    "data_processing_error": "Du musst der Verarbeitung deiner personenbezogenen Daten zustimmen",
    "data_processing_link": "Datenschutzhinweise für Inhalteanbieter",
    "details_subtitle": "Gib deine Benutzerinformationen ein",
    "details_title": "Nutzerdetails",
    "failure_disc": "Etwas ist schief gelaufen",
    "failure_title": "Anfrage fehlgeschlagen",
    "partner_bank": "MeinPlus Partner",
    "partner_bank_disclaimer": "Wähle deinen MeinPlus Partner",
    "paths": {
      "done": "Fertig",
      "options": {
        "partner": "MeinPlus Partner"
      },
      "partner_bank": "Bank / Partner",
      "user_details": "Nutzerdetails"
    },
    "role_options": {
      "event_manager": "ein Event Manager",
      "partner": "ein Partner Manager"
    },
    "select_bank": "Bank",
    "select_bank_disclaimer": "Wähle deine Bank aus",
    "success_instructions": "Weitere Anweisungen zur Aktivierung deines Kontos findest du in der E-Mail, die wir dir gerade gesendet haben.",
    "success_message": "Die Registrierung war erfolgreich.",
    "terms_and_conditions": "Zustimmung zu den {link}",
    "terms_and_conditions_error": "Du musst den Nutzungsbedingungen zustimmen",
    "terms_and_conditions_link": "Nutzungsbedingungen",
    "user_created": "Der Benutzer wurde erstellt",
    "user_created_message": "Bitte warte, bis der Bankmitarbeiter deine Anfrage akzeptiert."
  },
  "review": {
    "accept": "Bestätigen",
    "accepted": "Bestätigt",
    "decline": "Ablehnen",
    "declineMessage": "Nachricht",
    "declined": "Abgelehnt",
    "event": "Veranstaltung",
    "fields": {
      "city": "Stadt",
      "contact": "Kontakt",
      "description": "Beschreibung",
      "details": "Details",
      "discount_debit": "Rabatt",
      "email": "E-Mail",
      "link": "Link",
      "price": "Preis",
      "role": "Rolle",
      "state": "Status",
      "street": "Straße",
      "type": "Typ",
      "validity_start": "Gültigkeitsbeginn",
      "zip": "PLZ"
    },
    "nav": {
      "events": "Veranstaltungen",
      "offer": "Angebot",
      "offers": "Angebote",
      "organizer": "Veranstalter",
      "partner": "MeinPlus Partner",
      "user": "Benutzer",
      "users": "Benutzer"
    },
    "offer_title": "Angebot überprüfen",
    "organizer": "Veranstalter",
    "registrationTitle": "Benutzer überprüfen",
    "title": "Veranstaltung überprüfen",
    "userConfirmed": "Benutzer bestätigt",
    "venue": "Veranstaltungsort"
  },
  "routes": {
    "BankManagement": "Bankenverwaltung",
    "BankProfile": "Bank Profil",
    "ContentFeed": "News",
    "EventManagement": "Veranstaltungen",
    "PartnerOfferManagement": "Angebote",
    "RealEstatePage": "Immobilien",
    "ReviewPage": "Ausstehende Freigaben",
    "UserManagement": "Benutzer",
    "VideoCallPage": "Video Call"
  },
  "trpc_error": {
    "CANNOT_DELETE_LAST_BANK_ADMIN": "Du bist der letzte Administrator deiner Bank. Du kannst dein Konto erst löschen, wenn ein neuer Administrator angelegt wurde.",
    "EMAIL_EXISTS": "E-Mail existiert bereits",
    "ENTITY_IN_USE": "Das hat leider nicht funktioniert, der Datensatz ist noch in Verwendung.",
    "FORBIDDEN": "Unzureichende Berechtigungen für diese Aktion",
    "PHONE_EXISTS": "Telefon existiert bereits",
    "UNKNOWN_ERROR": "Unbekannter Fehler",
    "USER_NOT_FOUND": "Benutzer nicht gefunden"
  },
  "userMenu": {
    "profile": "Profil"
  },
  "userProfile": {
    "sections": {
      "actions": {
        "deleteAccount": {
          "confirmation": {
            "detail": "Dein Account wurde gelöscht.",
            "summary": "Erfolg"
          },
          "cta": "Konto löschen",
          "header": "Konto löschen",
          "message": "Bist du sicher, dass du dein Konto löschen möchtest?"
        },
        "deleteAvatar": {
          "confirmation": {
            "detail": "Dein Avatar wurde gelöscht.",
            "summary": "Erfolg"
          },
          "header": "Avatar löschen?",
          "message": "Bist du sicher, dass du deinen Avatar löschen möchtest?"
        },
        "headline": "Account Aktionen",
        "resetPassword": {
          "confirmation": {
            "detail": "An deine E-Mail-Adresse wurde eine Nachricht mit Anweisungen zum Ändern deines Passworts gesendet.",
            "summary": "Erfolgreich"
          },
          "cta": "Password ändern"
        }
      },
      "personalInformation": {
        "headline": "Persönliche Informationen"
      },
      "settings": {
        "headline": "Einstellungen",
        "locale": "Sprache"
      }
    },
    "title": "Profil"
  },
  "user_management": {
    "fields": {
      "email": "E-Mail-Adresse",
      "first_name": "Vorname",
      "roles": "Rollen",
      "surname": "Nachname"
    },
    "headlines": {
      "createUser": "Neuen Benutzer anlegen",
      "editUser": "Benutzer bearbeiten"
    },
    "menu": {
      "users": "Benutzer"
    },
    "no_users_found": "Keine Benutzer gefunden",
    "reset_password": "E-Mail zum Zurücksetzen des Passworts senden",
    "roles": {
      "bank_admin": "Bank Admin",
      "content_admin": "Content Admin",
      "content_feed_agent": "Content Feed Agent",
      "event_manager": "Event Manager",
      "organizer_agent": "Veranstaltungs Agent",
      "organizer_manager": "Veranstaltungs Manager",
      "partner_agent": "Partner Agent",
      "partner_manager": "Partner Manager",
      "system_admin": "System Admin",
      "venue_manager": "Venue Manager",
      "videocall_agent": "Video Call Agent"
    }
  },
  "validation_errors": {
    "country_code": "Das ausgewählte Land liegt außerhalb Europas oder existiert nicht",
    "digit": "Das Passwort muss mindestens eine Ziffer enthalten.",
    "email": "Dieses Feld muss eine gültige E-Mail-Adresse sein",
    "event_end": "Das Enddatum der Veranstaltung muss nach dem Startdatum der Veranstaltung liegen",
    "event_start": "Das Startdatum der Veranstaltung darf nicht vor dem Einlassdatum der Veranstaltung liegen",
    "is_not_email": "Dieses Feld ist keine gültige E-Mail-Adresse",
    "is_not_number": "Dieses Feld ist keine Zahl",
    "lowercase": "Das Passwort muss mindestens einen Kleinbuchstaben enthalten.",
    "max": "Dieses Feld darf höchstens {requiredLength} Zeichen enthalten",
    "min": "Dieses Feld muss mindestens {requiredLength} Zeichen enthalten",
    "nonempty": "Dieses Feld ist erforderlich",
    "pwd_no_match": "Passwörter stimmen nicht überein",
    "range_date": "Die Veranstaltung muss ein Enddatum haben",
    "special": "Das Passwort muss mindestens ein Sonderzeichen enthalten.",
    "too_big_string": "Dieses Feld darf höchstens {requiredLength} Zeichen enthalten",
    "uppercase": "Das Passwort muss mindestens einen Großbuchstaben enthalten.",
    "url": "Dieses Feld muss eine gültige URL sein"
  },
  "venue": {
    "address": "Adresse",
    "city": "Stadt",
    "countryCode": "Land",
    "lat": "Breite",
    "lng": "Längengrad",
    "title": "Titel",
    "zip": "PLZ"
  },
  "videoCall": {
    "device": {
      "audioinput": "Audio-Eingang (Mikrofon)",
      "audiooutput": "Audio-Ausgang",
      "videoinput": "Kamera"
    },
    "permissionError": {
      "detail": "Der Zugriff auf die Kamera und/oder das Mikrofon wurde verweigert, bitte überprüfe deine Browsereinstellungen",
      "summary": "Berechtigungsfehler"
    },
    "preview": {
      "previewClickHint": "klicken um die Vorschau anzuzeigen",
      "videoError": {
        "summary": "Fehler beim Erstellen der Kameravorschau"
      }
    },
    "settings": {
      "headline": "Einstellungen"
    },
    "status": {
      "joining": "Anruf wird beigetreten...",
      "leaving": "Anruf wird verlassen...",
      "offline": "Offline",
      "online": "Online",
      "ringing": "Eingehender Anruf"
    }
  }
}
